import { Avatar, Button, Dialog, DialogContent, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import commentService from '../../services/commentService';
import { useState } from 'react';
import { Comment } from '../../@types/comment';
import { usePlaylistStore } from '../../store/players.store';
import { useSongSelector } from '../../store/selectors/song.selector';
import { formatTime } from '../../utils/helpers';
import { formatDateMDTime } from '../../utils/date';
import { useAuth0 } from '@auth0/auth0-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

type CommentDialogProps = {
  onClose: () => void;
  open: boolean;
  stemId?: string;
  comment?: Comment;
  currentTime?: number;
};

export default function CommentDialog({ onClose, open, stemId, comment, currentTime }: CommentDialogProps) {
  const { user } = useAuth0();
  const [newMessage, setNewMessage] = useState('');
  const { getComments } = useSongSelector();
  const { currentVersion } = useSongSelector();
  const playlistState = usePlaylistStore(state => state.playListStates.find(playlistState => playlistState.versionId === currentVersion));

  let playlistEventEmitter: any;

  if (playlistState) {
    playlistEventEmitter = playlistState.eventEmitter;
  }

  const handleSaveComment = async () => {
    if (!newMessage) return;

    if (comment?.id) {
      await commentService.updateComment(comment?.id, newMessage);
      setNewMessage('');
    } else {
      await commentService.createComment(stemId!, currentTime!, newMessage);
      onClose();
    }

    await getComments();
  };

  const removeComment = async () => {
    await commentService.deleteComment(comment?.id!);
    onClose();
    await getComments();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          background: 'rgba(21, 21, 21, 1)',
          width: '100%',
          margin: 2,
          borderRadius: '16px',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '16px',
            padding: '1px',
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)',
            WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            zIndex: 0
          },
          zIndex: 1
        }
      }}
      sx={{ paddingInline: '24px', paddingBottom: '20px', paddingTop: '16px' }}
    >
      {comment?.id && (
        <>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={removeComment}>
              <CheckCircleOutlineOutlinedIcon opacity={0.5} />{' '}
            </IconButton>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon opacity={0.5} />{' '}
            </IconButton>
          </Stack>

          <Divider />
        </>
      )}

      <DialogContent>
        {!!comment?.commentMessages?.length ? (
          comment?.commentMessages?.map((message, index) => (
            <>
              <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" key={index} gap={1}>
                <Avatar
                  sx={{
                    bgcolor: '#008EF3',
                    color: '#FFFFFF',
                    width: '23px',
                    height: '23px',
                    fontFamily: 'DM Sans, sans-serif',
                    fontSize: '14px'
                  }}
                >
                  {message.createdBy?.name?.charAt(0).toUpperCase() ?? message.createdBy?.email?.charAt(0).toUpperCase()}
                </Avatar>
                <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '24px', color: '#FFFFFF', marginBottom: '3px' }}>
                  {message.createdBy.name ?? message.createdBy.email}{' '}
                  {index === 0 && (
                    <>
                      <span style={{ color: '#A7A7A7' }}>at</span>{' '}
                      <span
                        style={{ color: '#008EF3', fontWeight: '600', marginRight: '20px', cursor: 'pointer' }}
                        onClick={e => {
                          e.stopPropagation();
                          playlistEventEmitter.emit('select', currentTime, currentTime, null);
                          onClose();
                        }}
                      >
                        {formatTime(Math.ceil(comment.currentTime))}
                      </span>
                    </>
                  )}
                  <span style={{ color: '#A7A7A7' }}>{formatDateMDTime(message.createdAt, true)}</span>
                </Typography>
              </Stack>

              <Typography
                sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '24px', color: '#FFFFFF', marginBottom: '14px', marginLeft: '32px' }}
              >
                {message.commentText}
              </Typography>
            </>
          ))
        ) : (
          <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '24px', color: '#FFFFFF', marginBottom: '17px' }}>
            {user?.name} <span style={{ color: '#A7A7A7' }}>at</span>{' '}
            <span
              style={{ color: '#008EF3', fontWeight: '600', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                playlistEventEmitter.emit('select', currentTime, currentTime, null);
                onClose();
              }}
            >
              {formatTime(Math.ceil(currentTime!))}
            </span>{' '}
          </Typography>
        )}

        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            backgroundColor: 'rgba(75, 75, 75, 0.4)',
            border: '1px solid #ABABAB',
            borderRadius: '36px',
            paddingInline: '18px',
            marginBottom: '14px'
          }}
        >
          <TextField
            autoFocus
            value={newMessage}
            sx={{
              padding: 0,
              '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
                display: 'none'
              }
            }}
            margin="dense"
            placeholder={comment?.id ? 'Reply' : 'Comment'}
            size="small"
            autoComplete="off"
            variant="standard"
            fullWidth
            onChange={e => setNewMessage(e.target.value)}
          />
        </Stack>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'flex-end',
            gap: '8px'
          }}
        >
          {' '}
          {!comment?.id && (
            <Button
              onClick={onClose}
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '18.23px',
                letterSpacing: '-0.4%',
                paddingBlock: '11px',
                paddingInline: '18px',
                borderRadius: '36px',
                color: '#FFFFFF'
              }}
              variant="text"
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={() => handleSaveComment()}
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '18.23px',
              letterSpacing: '-0.4%',
              paddingBlock: '11px',
              paddingInline: '18px',
              borderRadius: '36px',
              backgroundColor: '#008EF3'
            }}
            variant="contained"
          >
            {comment?.id ? 'Reply' : 'Comment'}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
function useLayoutEffect(arg0: () => void, arg1: (HTMLInputElement | null)[]) {
  throw new Error('Function not implemented.');
}
