import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  Grid,
  InputBase,
  Menu,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NotificationType } from '../@types/notifications';
import { AudioFile, SoloMutedStems } from '../@types/songs';
import { stemToUpload } from '../@types/uploadFile';
import { ReactComponent as Download } from '../assets/icons/download.svg';
import notificationService from '../services/notificationService';
import trackEvent from '../services/trackService';
import { usePlaylistStore } from '../store/players.store';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { useUserStore } from '../store/user.store';
import { formatDateMDTime } from '../utils/date';
import DeleteStemDialog from './DeleteStemDialog';

export type StemItemProps = {
  stem: AudioFile;
  isSolo?: boolean;
  isMuted?: boolean;
  canEdit?: boolean;
  isOwner?: boolean;
  canDownload?: boolean;
  isVersionPlayer?: boolean;
  innerRef?: any;
  draggableProps?: any;
  dragHandleProps?: any;
  isReordering: boolean;
  playlistEventEmitter: any;
  playlist: any;
  play?: () => void;
  setSoloMuted: React.Dispatch<React.SetStateAction<Map<string, SoloMutedStems>>>;
  soloMutedStems: Map<string, SoloMutedStems>;
  index: number;
  setIsBackdrop: (boolean: boolean) => void;
  isStemsPlaying: boolean;
};

const volumeToSlider = (volume: number) => {
  return volume < 0 ? ((volume + 101) / 101) * 64 : 64 + (volume / 6) * 36;
};

const sliderToVolume = (sliderValue: number) => {
  return sliderValue <= 64 ? (sliderValue / 64) * 101 - 101 : ((sliderValue - 64) / 36) * 6;
};

export function StemItem({
  stem,
  canEdit,
  canDownload,
  isOwner,
  isSolo,
  isMuted,
  innerRef,
  draggableProps,
  dragHandleProps,
  isReordering,
  playlist,
  playlistEventEmitter,
  setSoloMuted,
  soloMutedStems,
  setIsBackdrop,
  index,
  isVersionPlayer,
  play,
  isStemsPlaying
}: StemItemProps) {
  const [volume, setVolume] = useState(stem.volume);
  const [firstRender, setFirstRender] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileLandscape = useMediaQuery('(max-height: 500px) and (orientation: landscape)');
  const { uploadingSongs } = useUploadFilesSelector();
  const {
    video,
    renameStem,
    stems,
    songParentId,
    changeSoloMutedStem,
    currentVersion,
    isVolumeMode,
    setIsDragDisabled,
    setStemVolume,
    volumeHistory,
    saveVolumeThisVersion,
    deleteVideo
  } = useSongSelector();
  const { user } = useAuth0();
  const userInfo = useUserStore(state => state.user);
  const renameInputRef = useRef<null | HTMLInputElement>(null);
  const changeVolumeInputRef = useRef<null | HTMLInputElement>(null);
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const { isCompressedExists, isOriginalStems, downloadStem } = useSongSelector();
  const userPlan = useUserStore(state => state.userPlan);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [isFromSave, setIsFromSave] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isEmbed = params.get('isEmbed') === 'true';
  const [isVolumeFocus, setIsVolumeFocus] = useState(false);
  const [isStemHover, setIsStemHover] = useState(false);
  const loadedStemsCount = usePlaylistStore(
    state => state.playListStates.find(playlistState => playlistState.versionId === currentVersion)?.loadedStemsCount
  );
  useEffect(() => {
    if (stem.id === video?.id && !firstRender) {
      setIsDeleting(true);
    }
    if (firstRender) {
      setFirstRender(false);
    }
  }, [deleteVideo]);
  useEffect(() => {
    if (isRenaming) {
      renameInputRef.current?.focus();
    }
  }, [isRenaming]);

  useEffect(() => {
    if (isVolumeFocus) {
      changeVolumeInputRef.current?.focus();
    }
  }, [isVolumeFocus]);

  useEffect(() => {
    !stem.mime.startsWith('audio/') &&
      !stem.mime.startsWith('video') &&
      enqueueSnackbar(`The format of ${stem.name} is not supported`, { variant: 'warning' });
  }, []);

  useEffect(() => {
    if (playlist) {
      (stem.mime.startsWith('audio/aiff') || stem.name.endsWith('.aif')) && mute();

      if (isFromSave) {
        if (isVersionPlayer !== undefined) {
          if (isVersionPlayer) {
            if (stem.solo) {
              soloExclusive(false);
            }
          } else {
            if (stem.muted) {
              mute();
            }

            if (stem.solo) {
              solo();
            }
          }

          setIsFromSave(false);
        }
      }
    }
  }, [playlist, isFromSave, isVersionPlayer]);

  useEffect(() => {
    const currentVolumeChange = volumeHistory?.volumeChanges.find(vc => vc.id === volumeHistory?.currentVolumeChangeId);

    if (currentVolumeChange) {
      const stemVolumeChange = currentVolumeChange.stemsVolumes.find(s => s.stemId === stem.id);

      stemVolumeChange && stemVolumeChange.volume !== volume && handleVolumeChange(stemVolumeChange.volume);
    }
  }, [volumeHistory?.currentVolumeChangeId]);

  useEffect(() => {
    if (!!playlist && playlistEventEmitter && loadedStemsCount === stems.length) {
      setTimeout(() => handleVolumeChange(stem.volume), 150);
    }
  }, [stem.volume, loadedStemsCount, playlist, playlistEventEmitter, isVolumeMode]);

  const uploadingStems = useMemo(() => {
    const stems: stemToUpload[] = [];
    uploadingSongs.forEach(uploadingSong => {
      stems.push(...uploadingSong.stems);
    });
    return stems;
  }, [uploadingSongs]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>, stem: AudioFile) => {
    setMenuEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuEl(null);
  };

  const handleDelete = () => {
    setIsDeleting(true);
  };
  const handleRenameStem = () => {
    setIsRenaming(true);
  };

  const handleRenameOnBlur = () => {
    setIsRenaming(false);
  };

  const handleKeyDownOnStemRename = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      renameStem(stem.id, renameInputRef.current?.value ?? stem.name);

      handleRenameOnBlur();
    }
  };
  const handleKeyDownOnVolumeChange = async (e: any) => {
    if (e.key === 'Enter') {
      if (!Number.isNaN(+parseFloat(e.target.value))) {
        handleVolumeChange(+e.target.value);
        setStemVolume(stem.id, +e.target.value);
        setIsVolumeFocus(false);
        saveVolumeThisVersion();
      }
    }
  };
  const handleBlurVolumeChange = async (e: any) => {
    if (!Number.isNaN(+parseFloat(e.target.value))) {
      handleVolumeChange(+e.target.value);
      setStemVolume(stem.id, +e.target.value);
      setIsVolumeFocus(false);
      saveVolumeThisVersion();
    }
  };

  const handleVolumeChange = (newVolume: number) => {
    if (newVolume <= -50) {
      newVolume = -Infinity;
    }
    setVolume(newVolume);
    playlistEventEmitter.emit('volumechange', Math.pow(10, newVolume / 20) * 100, playlist.tracks[index]);
  };
  const mute = () => {
    if (soloMutedStems.get(stem.id)?.solo) {
      playlistEventEmitter.emit('solo', { track: playlist.tracks[index], index });

      setSoloMuted(prevState => {
        const newMap = new Map(prevState);
        newMap.set(stem.id, { ...newMap.get(stem.id), solo: false } as SoloMutedStems);
        return newMap;
      });
    }

    playlistEventEmitter.emit('mute', { track: playlist.tracks[index], index });

    setSoloMuted(prevState => {
      const newMap = new Map(prevState);
      !isFromSave && (isOwner || canEdit) && changeSoloMutedStem(stem.id, false, !newMap.get(stem.id)?.muted ?? false);
      newMap.set(stem.id, { ...newMap.get(stem.id), muted: !newMap.get(stem.id)?.muted } as SoloMutedStems);
      return newMap;
    });
  };

  const solo = () => {
    if (soloMutedStems.get(stem.id)?.muted) {
      playlistEventEmitter.emit('mute', { track: playlist.tracks[index], index });

      setSoloMuted(prevState => {
        const newMap = new Map(prevState);
        newMap.set(stem.id, { ...newMap.get(stem.id), muted: false } as SoloMutedStems);
        return newMap;
      });
    }

    playlistEventEmitter.emit('solo', { track: playlist.tracks[index], index });

    setSoloMuted(prevState => {
      const newMap = new Map(prevState);
      !isFromSave && (isOwner || canEdit) && changeSoloMutedStem(stem.id, !newMap.get(stem.id)?.solo ?? false, false);
      newMap.set(stem.id, { ...newMap.get(stem.id), solo: !newMap.get(stem.id)?.solo } as SoloMutedStems);
      return newMap;
    });
  };
  const volumeStep = useMemo(() => {
    if (volume > -10) return 0.1;
    else if (volume > -20) return 0.2;
    else if (volume > -30) return 0.5;
    else if (volume > -40) return 1;
    else if (volume > -50) return 2;
    else return 5;
  }, [volume]);
  const soloExclusive = (shouldPlay: boolean = true) => {
    let i = -1;
    let currentIndex = 0;
    let foundKey: string | undefined;

    soloMutedStems.forEach((value, key) => {
      if (i === -1 && value.solo) {
        i = currentIndex;
        foundKey = key;
      }
      currentIndex++;
    });

    if (i !== -1) {
      shouldPlay && play && play();

      playlistEventEmitter.emit('solo', { track: playlist.tracks[i], i });

      setSoloMuted(prevState => {
        const newMap = new Map(prevState);

        if (foundKey) {
          newMap.set(foundKey, { ...newMap.get(foundKey), solo: false } as SoloMutedStems);
        }

        return newMap;
      });
    }

    playlistEventEmitter.emit('solo', { track: playlist.tracks[index], index });

    setSoloMuted(prevState => {
      const newMap = new Map(prevState);
      newMap.set(stem.id, { ...newMap.get(stem.id), solo: !newMap.get(stem.id)?.solo } as SoloMutedStems);
      return newMap;
    });

    stems.forEach(s => {
      if (stem.id !== s.id) {
        !isFromSave && changeSoloMutedStem(s.id, false, false);
      }
    });

    !isFromSave && changeSoloMutedStem(stem.id, true, false);
    shouldPlay && play && play();
  };

  const pauseSoloExclusive = () => {
    let i = -1;
    let currentIndex = 0;
    let foundKey: string | undefined;

    soloMutedStems.forEach((value, key) => {
      if (i === -1 && value.solo) {
        i = currentIndex;
        foundKey = key;
      }
      currentIndex++;
    });

    if (i !== -1) {
      playlistEventEmitter.emit('solo', { track: playlist.tracks[i], i });

      setSoloMuted(prevState => {
        const newMap = new Map(prevState);

        if (foundKey) {
          newMap.set(foundKey, { ...newMap.get(foundKey), solo: false } as SoloMutedStems);
        }

        return newMap;
      });
    }

    play && play();
  };

  const handleDownload = async () => {
    setIsBackdrop(true);
    try {
      await downloadStem(stem.id, stem.name);

      setIsBackdrop(false);

      !!user && notificationService.createNotification(NotificationType.DownloadSong, songParentId!);

      trackEvent('download', { type: 'stem', downloadStems: true });
    } catch (error) {
      console.log(error);
      setIsBackdrop(false);
    }
  };
  return (
    <React.Fragment key={stem.id}>
      {isDeleting && (
        <DeleteStemDialog
          stemId={stem.id}
          name={stem.name}
          onClose={() => setIsDeleting(false)}
          onDelete={() => {
            playlistEventEmitter.emit(
              'removeTrack',
              playlist.tracks.find((track: any) => track.name === stem?.name)
            );
          }}
          open={isDeleting}
        />
      )}
      <Menu
        id="stemDetailsMenu"
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5
            }
          }
        }}
        MenuListProps={{
          'aria-labelledby': `stemDetailsMenu`
        }}
      >
        <MenuItem
          onClick={() => {
            trackEvent('download', { type: 'stem' });
            handleDownload();
            closeMenu();
          }}
        >
          <DownloadIcon sx={{ pr: 1 }} />
          Download
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRenameStem();
            closeMenu();
          }}
        >
          <ReplayIcon sx={{ pr: 1 }} /> Rename
        </MenuItem>
        {!userInfo?.email.includes('guest-user') && (
          <MenuItem
            onClick={() => {
              handleDelete();
              closeMenu();
            }}
          >
            <DeleteIcon sx={{ pr: 1 }} /> Delete
          </MenuItem>
        )}
      </Menu>
      <Stack
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
        sx={{
          background: '#1F1F1F',
          maxHeight: isMobile || isMobileLandscape ? '115px' : '92px',
          minHeight: isMobile || isMobileLandscape ? '115px' : '92px',
          ...(!isReordering && { cursor: 'initial !important' }),
          borderRight: '1px solid #494949'
        }}
        direction="row"
        py={1.5}
        pl={isTablet ? 1.25 : 3}
        pr={isTablet ? 1.25 : 2}
        justifyContent="space-between"
        overflow={'hidden'}
        position="relative"
        onMouseEnter={() => setIsStemHover(true)}
        onMouseLeave={() => {
          setIsStemHover(false);
        }}
      >
        {isMobile || isMobileLandscape ? (
          <Grid container spacing={1} direction="column" justifyContent={'space-between'} flexWrap={'nowrap'}>
            <Grid item>
              <Stack height={1} justifyContent="center">
                {isRenaming ? (
                  <TextField
                    sx={{ '& .MuiInputBase-root': { maxHeight: '28px' } }}
                    size="small"
                    inputRef={renameInputRef}
                    onKeyDown={handleKeyDownOnStemRename}
                    onBlur={handleRenameOnBlur}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    fontSize={16}
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                  >
                    {stem.name}
                    {stem.createdAt && stem.uploadedBy && (
                      <Typography
                        fontSize={12}
                        color="text.secondary"
                        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      >
                        {`${formatDateMDTime(stem.createdAt)} by ${stem.uploadedBy}`}
                      </Typography>
                    )}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" justifyContent={'space-between'}>
                {!isVersionPlayer && (
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      disabled={stem.mime.startsWith('audio/aiff') || stem.name.endsWith('.aif')}
                      sx={{
                        mr: 1,
                        minWidth: 'unset',
                        width: '40px',
                        height: '40px',
                        border: isSolo ? '1px solid white' : 'unset',
                        boxShadow: 'none',
                        fontSize: '16px'
                      }}
                      onClick={() => {
                        solo();
                      }}
                    >
                      S
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      disabled={stem.mime.startsWith('audio/aiff') || stem.name.endsWith('.aif')}
                      sx={{
                        minWidth: 'unset',
                        width: '40px',
                        height: '40px',
                        border: isMuted ? '1px solid white' : 'unset',
                        boxShadow: 'none',
                        fontSize: '16px'
                      }}
                      onClick={() => {
                        mute();
                      }}
                    >
                      M
                    </Button>
                  </Box>
                )}

                {isVersionPlayer && (
                  <Button
                    size="small"
                    sx={{
                      minWidth: 'unset',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%'
                    }}
                    disabled={!stems?.length}
                    variant="contained"
                    color="info"
                    onClick={() => {
                      if (soloMutedStems.get(stem.id)?.solo) {
                        if (!isStemsPlaying) {
                          play && play();
                        } else {
                          pauseSoloExclusive();
                        }
                      } else {
                        soloExclusive();
                      }
                    }}
                  >
                    {soloMutedStems.get(stem.id)?.solo ? (
                      <PauseIcon fontSize="small" />
                    ) : (
                      <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />
                    )}
                  </Button>
                )}

                {(isOwner || canEdit) && !isEmbed ? (
                  <Button
                    disabled={uploadingStems.some(s => s.stemId === stem.id)}
                    id="stemDetailsButton"
                    aria-controls={Boolean(menuEl) ? 'stemDetailsMenu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(menuEl) ? 'true' : undefined}
                    onClick={e => {
                      openMenu(e, stem as AudioFile);
                    }}
                    variant="contained"
                    disableElevation={true}
                    size="small"
                    color="secondary"
                    sx={{ height: '40px', width: '40px', minWidth: 'unset', boxShadow: 'none' }}
                  >
                    <MoreVertIcon sx={{ width: '16px', height: '16px' }} />
                  </Button>
                ) : (
                  canDownload && (
                    <Tooltip title={'Download stem'}>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          trackEvent('download', { type: 'stem' });
                          handleDownload();
                          closeMenu();
                        }}
                        disableElevation={true}
                        sx={{
                          height: '40px',
                          width: '40px',
                          minWidth: 'unset',
                          padding: '0 8px',
                          boxShadow: 'none',
                          '&.Mui-disabled': {
                            background: 'rgb(75, 75, 75, 0.5)'
                          }
                        }}
                      >
                        <Download />
                      </Button>
                    </Tooltip>
                  )
                )}
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent={'space-between'}
            alignItems="center"
            flexWrap={'nowrap'}
            onMouseEnter={() => {
              setShowPlayButton(true);
            }}
            onMouseLeave={() => {
              setShowPlayButton(false);
            }}
          >
            {!stem.mime.startsWith('audio/') && !stem.mime.startsWith('video') && (
              <Grid item xs={'auto'} style={{ flexShrink: 0 }}>
                <Tooltip title="This file format is not supported">
                  <WarningAmberIcon sx={{ fontSize: 14, color: 'orange' }} />
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={'auto'} style={{ flexShrink: 0 }}>
              {!isVersionPlayer && (
                <Box>
                  <Tooltip title="Solo">
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      disabled={stem.mime.startsWith('audio/aiff') || stem.name.endsWith('.aif')}
                      sx={{
                        mr: 1,
                        minWidth: 'unset',
                        width: '28px',
                        height: '28px',
                        border: isSolo ? '1px solid white' : 'unset',
                        boxShadow: 'none'
                      }}
                      onClick={() => {
                        solo();
                      }}
                    >
                      S
                    </Button>
                  </Tooltip>
                  <Tooltip title="Mute">
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      disabled={stem.mime.startsWith('audio/aiff') || stem.name.endsWith('.aif')}
                      sx={{
                        minWidth: 'unset',
                        width: '28px',
                        height: '28px',
                        border: isMuted ? '1px solid white' : 'unset',
                        boxShadow: 'none'
                      }}
                      onClick={() => {
                        mute();
                      }}
                    >
                      M
                    </Button>
                  </Tooltip>
                </Box>
              )}
              {isVersionPlayer && (showPlayButton || soloMutedStems.get(stem.id)?.solo) && (
                <Tooltip title={'Play stems'}>
                  <Button
                    size="small"
                    sx={{
                      minWidth: 'unset',
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%'
                    }}
                    disabled={!stems?.length}
                    variant="contained"
                    color="info"
                    onClick={() => {
                      if (soloMutedStems.get(stem.id)?.solo) {
                        if (!isStemsPlaying) {
                          play && play();
                        } else {
                          pauseSoloExclusive();
                        }
                      } else {
                        soloExclusive();
                      }
                    }}
                  >
                    {soloMutedStems.get(stem.id)?.solo && isStemsPlaying ? (
                      <PauseIcon fontSize="small" />
                    ) : (
                      <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />
                    )}
                  </Button>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={'auto'} style={{ flexShrink: 1, minWidth: '20px', flexGrow: 1 }}>
              <Stack height={1} justifyContent="center">
                {isRenaming ? (
                  <TextField
                    sx={{ '& .MuiInputBase-root': { maxHeight: '28px' } }}
                    size="small"
                    inputRef={renameInputRef}
                    onKeyDown={handleKeyDownOnStemRename}
                    onBlur={handleRenameOnBlur}
                  />
                ) : (
                  <Tooltip title={stem.name}>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      fontSize={12}
                      sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    >
                      {stem.name}

                      {stem.createdAt && stem.uploadedBy && (
                        <Tooltip title={`${formatDateMDTime(stem.createdAt)} by ${stem.uploadedBy}`}>
                          <Typography
                            fontSize={10}
                            color="rgba(255, 255, 255, 0.5)"
                            sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                          >
                            {`${formatDateMDTime(stem.createdAt)} by ${stem.uploadedBy}`}
                          </Typography>
                        </Tooltip>
                      )}
                    </Typography>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid mr={1} item xs={'auto'} style={{ flexShrink: 0 }}>
              {isVolumeMode && (
                <Stack
                  direction="column"
                  onMouseEnter={() => {
                    setIsDragDisabled(true);
                  }}
                  onMouseLeave={() => {
                    setIsDragDisabled(false);
                  }}
                  onDoubleClick={() => setIsVolumeFocus(true)}
                >
                  <Box sx={{ position: 'absolute' }}>
                    {isVolumeFocus && (
                      <InputBase
                        sx={{ width: '25px', top: '-6px', position: 'relative', right: volume > 0 ? '-40px' : '-35px' }}
                        inputRef={changeVolumeInputRef}
                        defaultValue={parseFloat(volume.toFixed(1))}
                        onBlur={handleBlurVolumeChange}
                        onKeyDown={handleKeyDownOnVolumeChange}
                        inputProps={{
                          style: {
                            padding: 0,
                            fontSize: '10px',
                            background: 'rgba(0, 142, 243, 1)'
                          },
                          type: 'text',
                          pattern: '[-+]?[0-9]*.?[0-9]+|&#8734;',
                          onInput: e => {
                            const inputElement = e.target as HTMLInputElement;
                            if (+inputElement.value <= -50) {
                              inputElement.value = '-Infinity';
                            } else if (+inputElement.value > 6) {
                              inputElement.value = '6';
                            }
                          },
                          sx: {
                            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                              display: 'none'
                            }
                          }
                        }}
                      />
                    )}
                    {isStemHover && !isVolumeFocus && (
                      <Typography textAlign="center" position={'relative'} top="-4px" right={volume > 0 ? '-40px' : '-35px'} fontSize={10}>
                        {volume.toFixed(1)}
                      </Typography>
                    )}
                  </Box>
                  <Slider
                    sx={t => ({
                      width: '82px',
                      px: 0.25,
                      '&.MuiSlider-root': {
                        height: '19px !important'
                      },
                      '& .MuiSlider-track': {
                        backgroundColor: 'black',
                        opacity: 1,
                        border: 'none'
                      },
                      '& .MuiSlider-thumb': {
                        width: 16,
                        height: 16,
                        backgroundColor: '#808080',

                        '&::before': {
                          boxShadow: 'none'
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                          boxShadow: 'none'
                        }
                      },
                      '& .MuiSlider-rail': {
                        opacity: 1,
                        backgroundColor: 'black'
                      }
                    })}
                    value={volume}
                    onChange={(_e, newSliderValue) => {
                      if (typeof newSliderValue === 'number') {
                        const newVolume = newSliderValue;
                        handleVolumeChange(newVolume);
                      }
                    }}
                    onChangeCommitted={(_e, newSliderValue) => {
                      if (typeof newSliderValue === 'number') {
                        setStemVolume(stem.id, volume <= -50 ? -101 : volume);
                        saveVolumeThisVersion();
                      }
                    }}
                    aria-label="Volume"
                    orientation="horizontal"
                    valueLabelDisplay="off"
                    step={volumeStep}
                    min={-50}
                    max={15}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item xs={'auto'} style={{ flexShrink: 0 }}>
              {(isOwner || canEdit) && !isEmbed ? (
                <Tooltip title={'Download, Rename, or Delete stem'}>
                  <Button
                    disabled={uploadingStems.some(s => s.stemId === stem.id)}
                    id="stemDetailsButton"
                    aria-controls={Boolean(menuEl) ? 'stemDetailsMenu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(menuEl) ? 'true' : undefined}
                    onClick={e => {
                      openMenu(e, stem as AudioFile);
                    }}
                    variant="contained"
                    disableElevation={true}
                    size="small"
                    color="secondary"
                    sx={{
                      height: '28px',
                      width: '28px',
                      minWidth: 'unset',
                      boxShadow: 'none'
                    }}
                  >
                    <MoreVertIcon sx={{ width: '16px', height: '16px' }} />
                  </Button>
                </Tooltip>
              ) : (
                canDownload &&
                !isEmbed && (
                  <Tooltip title={'Download stem'}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        trackEvent('download', { type: 'stem' });
                        handleDownload();
                        closeMenu();
                      }}
                      disableElevation={true}
                      sx={{
                        height: '28px',
                        width: '28px',
                        minWidth: 'unset',
                        padding: '0 8px',
                        boxShadow: 'none',
                        '&.Mui-disabled': {
                          background: 'rgb(75, 75, 75, 0.5)'
                        }
                      }}
                    >
                      <Download />
                    </Button>
                  </Tooltip>
                )
              )}
            </Grid>
          </Grid>
        )}
        {}
      </Stack>

      <Box height="1px" width="100%" sx={{ backgroundColor: '#494949' }} />
    </React.Fragment>
  );
}
