import { defaultFolderName, useFoldersStore } from '../folders.store';

export function useFoldersSelector() {
  // State
  const highlightedSongs = useFoldersStore(state => state.highlightedSongs);
  const folders = useFoldersStore(state => state.folders.filter(folder => folder.name !== defaultFolderName));
  const foldersBackup = useFoldersStore(state => state.foldersBackup.filter(folder => folder.name !== defaultFolderName));
  const notFilteredFolders = useFoldersStore(state => state.folders);
  const sharedFolderUsers = useFoldersStore(state => state.sharedFolderUsers);
  const foldersError = useFoldersStore(state => state.foldersError);
  const isFoldersLoading = useFoldersStore(state => state.isFoldersLoading);
  const isRenameLoading = useFoldersStore(state => state.isRenameLoading);
  const selectedFolder = useFoldersStore(state => state.selectedFolder);
  const defaultFolder = useFoldersStore(
    state =>
      state.folders.find(folder => folder.name === defaultFolderName && folder.songs.length > 0 && folder.children.length > 0) ??
      state.folders.find(folder => folder.name === defaultFolderName)
  );

  // Actions
  const backupFolders = useFoldersStore(state => state.backupFolders);
  const restoreBackup = useFoldersStore(state => state.restoreBackup);
  const getFolders = useFoldersStore(state => state.getFolders);
  const createFolder = useFoldersStore(state => state.createFolder);
  const deleteFolder = useFoldersStore(state => state.deleteFolder);
  const renameFolder = useFoldersStore(state => state.renameFolder);
  const createSongInFolder = useFoldersStore(state => state.createSongInFolder);
  const deleteFolderSong = useFoldersStore(state => state.deleteFolderSong);
  const moveFolderSong = useFoldersStore(state => state.moveFolderSong);
  const renameSongInSidebar = useFoldersStore(state => state.renameSongInSidebar);
  const renameSong = useFoldersStore(state => state.renameSong);
  const shareFolderByInvite = useFoldersStore(state => state.shareFolderByInvite);
  const getFolderSharedUsers = useFoldersStore(state => state.getFolderSharedUsers);
  const toggleFolder = useFoldersStore(state => state.toggleFolder);
  const deleteFolderSharedUser = useFoldersStore(state => state.deleteFolderSharedUser);
  const setFolders = useFoldersStore(state => state.setFolders);
  const openFolder = useFoldersStore(state => state.openFolder);
  const moveFolder = useFoldersStore(state => state.moveFolder);
  const moveToRootFolder = useFoldersStore(state => state.moveToRootFolder);
  const setFolderArtBlob = useFoldersStore(state => state.setFolderArtBlob);
  const setSongArtBlob = useFoldersStore(state => state.setSongArtBlob);
  const addHighlightedSong = useFoldersStore(state => state.addHighlightedSong);
  const removeHighlightedSong = useFoldersStore(state => state.removeHighlightedSong);
  const clearHighlightedSongs = useFoldersStore(state => state.clearHighlightedSongs);
  const clearHighlightedSongsAndFolders = useFoldersStore(state => state.clearHighlightedSongsAndFolders);
  const setSelectedFolder = useFoldersStore(state => state.setSelectedFolder);
  const addHighlightedSongs = useFoldersStore(state => state.addHighlightedSongs);
  return {
    highlightedSongs,
    defaultFolder,
    folders,
    notFilteredFolders,
    foldersError,
    isFoldersLoading,
    isRenameLoading,
    sharedFolderUsers,
    foldersBackup,
    selectedFolder,
    addHighlightedSongs,
    setSelectedFolder,
    clearHighlightedSongsAndFolders,
    backupFolders,
    restoreBackup,
    addHighlightedSong,
    removeHighlightedSong,
    clearHighlightedSongs,
    getFolders,
    createFolder,
    deleteFolder,
    renameFolder,
    createSongInFolder,
    deleteFolderSong,
    moveFolderSong,
    renameSongInSidebar,
    renameSong,
    shareFolderByInvite,
    getFolderSharedUsers,
    deleteFolderSharedUser,
    toggleFolder,
    setFolders,
    openFolder,
    moveFolder,
    moveToRootFolder,
    setFolderArtBlob,
    setSongArtBlob
  };
}
