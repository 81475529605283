import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import commentService from '../../services/commentService';
import { useSongSelector } from '../../store/selectors/song.selector';
import { formatDateMDTime } from '../../utils/date';
import { formatTime } from '../../utils/helpers';
import { CommentHistorySidebarItem } from './CommentHistorySidebarItem';

type CommentHistorySidebarProps = {
  closeSidebar: () => void;
};

export function CommentHistorySidebar({ closeSidebar }: CommentHistorySidebarProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { comments } = useSongSelector();
  const containerRef = useRef<HTMLDivElement | undefined>(undefined);
  const commentHistoryContainerRef = useRef<HTMLDivElement>();
  const [renderScroll, setRenderScroll] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      setRenderScroll(true);
    }
  }, [containerRef.current]);

  return (
    <>
      <Box ref={commentHistoryContainerRef} />
      <Stack
        height={1}
        sx={{ backgroundColor: '#151515', borderRadius: '8px', px: 2, pb: 2, py: 3.5, cursor: 'default' }}
        position="relative"
      >
        <IconButton
          size="small"
          onClick={closeSidebar}
          sx={{ maxHeight: '30px', minHeight: '30px', maxWidth: '30px', position: 'absolute', right: 14, top: 14 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography ml={1.25} mb={2.75}>
          Comments
        </Typography>
        <Box ref={containerRef} />
        {renderScroll && (
          <SimpleBar
            style={{
              maxHeight: `calc(100svh - 52px - ${containerRef.current?.offsetTop ?? 0}px - ${
                commentHistoryContainerRef.current?.offsetTop ?? 0
              }px)`,
              width: '100%'
            }}
          >
            <Stack gap={1.5}>
              {comments.map(comment => (
                <Tooltip key={comment.id} title={'Comment History'}>
                  <CommentHistorySidebarItem comment={comment} />
                </Tooltip>
              ))}
            </Stack>
          </SimpleBar>
        )}
      </Stack>
    </>
  );
}
