import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useFoldersStore } from '../store/folders.store';
import { useSongSelector } from '../store/selectors/song.selector';
import { DialogCloseButton } from './DialogCloseButton';

type DeleteStemDialogProps = {
  stemId: string;
  name: string;
  onClose: () => void;
  onDelete: () => void;
  open: boolean;
  isCached?: boolean;
};

export default function DeleteStemDialog({ stemId, name, onClose, open, onDelete }: DeleteStemDialogProps) {
  const { folderId, songId } = useParams();
  const { deleteStem, video } = useSongSelector();
  const { updateSong } = useFoldersStore();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();

  const handleDeleteStem = async () => {
    await deleteStem(stemId);
    if (folderId && songId) {
      updateSong(folderId, songId, user?.name || user?.email);
    }

    enqueueSnackbar('Stem deleted successfully', { variant: 'success' });

    onDelete();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xs'}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>
        Delete {name} {stemId === video?.id ? 'video' : 'stem'}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography component={'span'} display={'block'}>
            Warning
          </Typography>
          This can’t be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => handleDeleteStem()} size="small" color="error" variant="contained">
          Delete stem
        </Button>
      </DialogActions>
    </Dialog>
  );
}
