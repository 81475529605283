import { Typography } from '@mui/material';
import { Navigate, Outlet, RouteObject, createBrowserRouter } from 'react-router-dom';
import NotFoundComponent from '../components/NotFoundPage';
import { UploadPage } from '../components/UploadPage';
import { DragSelectProvider } from '../hooks/DragSelectProvider';
import DashboardLayout from '../layouts/DashboardLayout/DashboardLayout';
import MainLayout from '../layouts/MainLayout/MainLayout';
import Account from '../pages/Account';
import Billings from '../pages/billings';
import { FAQs } from '../pages/FAQs';
import FilePreview from '../pages/FilePreview';
import { MainSongPage } from '../pages/MainSongPage';
import { Organization } from '../pages/Organization';
import Plans from '../pages/Plans';
import { RequestAccessPage } from '../pages/RequestAccessPage';
import { VerificationEmailPage } from '../pages/VerificationEmailPage';
import { Widget } from '../pages/Widget';
import { WidgetTest } from '../pages/WidgetTest';
import { AuthGuard } from '../utils/AuthGuard';

const routes: RouteObject[] = [
  { path: 'widget', element: <Widget /> },
  { path: 'widget-test', element: <WidgetTest /> },
  {
    path: '',
    element: (
      <AuthGuard>
        <DragSelectProvider>
          <MainLayout />
        </DragSelectProvider>
      </AuthGuard>
    ),
    errorElement: <></>,
    children: [
      { path: '', element: <Navigate to="dashboard" replace /> },
      { path: 'email-verification', element: <VerificationEmailPage /> },
      { path: 'about', element: <Typography>Coming soon...</Typography> },
      {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
          { path: '', element: <UploadPage />, index: true },
          {
            path: ':folderId',
            element: <Outlet />,
            children: [
              { path: '', element: <UploadPage />, index: true },
              {
                path: ':songId',
                children: [
                  { path: '', element: <MainSongPage />, index: true },
                  { path: 'file', element: <FilePreview /> }
                ],
                element: <Outlet />
              }
            ]
          },
          {
            path: 'public',
            element: <Outlet />,
            children: [
              {
                path: ':folderId',
                element: <Outlet />,
                children: [
                  { path: '', element: <UploadPage />, index: true },
                  {
                    path: ':songId',
                    children: [
                      { path: '', element: <MainSongPage />, index: true },
                      { path: 'file', element: <FilePreview /> }
                    ],
                    element: <Outlet />
                  }
                ]
              }
            ]
          }
        ]
      },
      { path: 'request-access', element: <RequestAccessPage /> },
      { path: 'account', element: <DashboardLayout />, children: [{ path: '', element: <Account />, index: true }] },
      { path: 'plans', element: <DashboardLayout />, children: [{ path: '', element: <Plans />, index: true }] },
      { path: 'billings', element: <DashboardLayout />, children: [{ path: '', element: <Billings />, index: true }] },
      { path: 'organization', element: <DashboardLayout />, children: [{ path: '', element: <Organization />, index: true }] },
      { path: 'faqs', element: <DashboardLayout />, children: [{ path: '', element: <FAQs />, index: true }] },
      { path: 'termsofservices', element: <Typography>To be terms of services page...</Typography> },
      { path: 'privacypolicy', element: <Typography>To be privacy policy page...</Typography> },
      {
        path: '*',
        element: <NotFoundComponent />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);
