import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import { useAuth0 } from '@auth0/auth0-react';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import FolderDropDown from '../layouts/DashboardLayout/components/FolderDropDown';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { DialogCloseButton } from './DialogCloseButton';

type MoveDialogProps = {
  onClose: () => void;
  open: boolean;
  setMovingSongToFolder: React.Dispatch<React.SetStateAction<null | { folderName: string; songName: string }>>;
};

export function MoveDialog({ onClose, open, setMovingSongToFolder }: MoveDialogProps) {
  const { highlightedSongs, moveFolderSong, clearHighlightedSongsAndFolders, folders, defaultFolder, selectedFolder } =
    useFoldersSelector();
  const [isBackdrop, setIsBackdrop] = useState(false);
  const { user } = useAuth0();
  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
    >
      <DialogTitle
        sx={{
          width: '90%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500'
        }}
      >
        Move
      </DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent dividers sx={{ p: 2 }}>
        <Grid rowSpacing={1.25} container direction={'column'}>
          {folders
            ?.filter(f => f.name !== 'example')
            ?.map(folder => (
              <Grid item key={folder.id} maxWidth={'100% !important'}>
                <FolderDropDown
                  folder={folder}
                  setMovingSongToFolder={setMovingSongToFolder}
                  isSharingFolder={false}
                  setIsBackdrop={setIsBackdrop}
                  showOnlyFolders={true}
                />
              </Grid>
            ))}
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'start' }}>
        <Button
          disabled={!selectedFolder}
          sx={{ mr: 1 }}
          onClick={async () => {
            clearHighlightedSongsAndFolders();
            onClose();
            for (const song of highlightedSongs) {
              setMovingSongToFolder({ folderName: selectedFolder?.name || '', songName: song.name });
              await moveFolderSong(
                { songId: song.id, currentFolderId: song.folderId, newFolderId: selectedFolder?.id! },
                user?.name || user?.email
              );
              enqueueSnackbar('Song moved successfully', { variant: 'success' });
              setMovingSongToFolder(null);
            }
          }}
          color="secondary"
          variant="contained"
        >
          move
        </Button>
        {selectedFolder?.name ? 'To ' + selectedFolder?.name + ' folder' : ''}
      </DialogActions>
    </Dialog>
  );
}
