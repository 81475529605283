import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { ReactComponent as VideoIcon } from '../assets/icons/big_video.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { useSongSelector } from '../store/selectors/song.selector';
import { UploadArea } from './UploadArea';

export interface VideoPlayerRef {
  play: () => void;
  pause: () => void;
  adjustTimeBy: (delta: number) => void;
}

interface VideoPlayerProps {
  uploadVideo: (fileList?: FileList, fileSystemEntry?: FileSystemEntry[]) => Promise<void>;
  resizeScale?: number;
  mainContainerWidth?: number;
  isVideoLoaded: boolean;
  setIsVideoLoaded: (value: boolean) => void;
}

interface ResizeStartCallbackData {
  node: HTMLElement;
  size: { width: number; height: number };
  handle: string;
}

const VideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  ({ setIsVideoLoaded, isVideoLoaded, uploadVideo, resizeScale = 1, mainContainerWidth }, ref) => {
    const videoElementRef = useRef<HTMLVideoElement | null>(null);
    const { deleteStem, video, incrementDeleteVideo } = useSongSelector();
    const [isEditingMode, setIsEditingMode] = useState(false);

    const [dimensions, setDimensions] = useState({ width: 480, height: 270 });
    const [aspectRatio, setAspectRatio] = useState(16 / 9);
    const [startDimensions, setStartDimensions] = useState({ width: 480, height: 270 });
    const videoContainer = useRef<HTMLDivElement | null>(null);
    const [videoOffset, setVideoOffset] = useState<number>(0);
    const [maxDimensions, setMaxDimensions] = useState({ width: 0, height: 0 });
    const [isResizing, setIsResizing] = useState(false);
    const [naturalSize, setNaturalSize] = useState({ width: 0, height: 0 });
    const [hasReachedMaxSize, setHasReachedMaxSize] = useState(false);

    useEffect(() => {
      if (videoContainer.current?.offsetTop && !videoOffset) {
        setVideoOffset(videoContainer.current?.offsetTop);
      }
    }, [videoContainer, videoOffset]);

    useEffect(() => {
      const updateMaxDimensions = () => {
        const windowHeight = window.innerHeight;
        const maxWidth = mainContainerWidth ? mainContainerWidth - 48 : 800;
        const maxHeight = windowHeight - videoOffset - 154 - 28 - 83 - 16;

        setMaxDimensions({
          width: Math.max(200, maxWidth),
          height: Math.max(150, maxHeight)
        });

        if (dimensions.width > maxWidth || dimensions.height > maxHeight) {
          let newWidth = dimensions.width;
          let newHeight = dimensions.height;

          if (dimensions.width > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
          }

          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
          }

          setDimensions({ width: newWidth, height: newHeight });
        }

        const isNearMaxWidth = Math.abs(dimensions.width - maxWidth) < 5;
        const isNearMaxHeight = Math.abs(dimensions.height - maxHeight) < 5;
        setHasReachedMaxSize(isNearMaxWidth || isNearMaxHeight);
      };

      updateMaxDimensions();
      window.addEventListener('resize', updateMaxDimensions);
      return () => window.removeEventListener('resize', updateMaxDimensions);
    }, [mainContainerWidth, videoOffset, dimensions, aspectRatio]);

    // Full preload and assign Blob URL
    useEffect(() => {
      let blobUrl: string;

      const preloadVideo = async () => {
        if (!video?.url) return;
        setIsVideoLoaded(false);

        try {
          const res = await fetch(video.url);
          const blob = await res.blob();
          blobUrl = URL.createObjectURL(blob);
          if (videoElementRef.current) {
            videoElementRef.current.src = blobUrl;
          }
        } catch (err) {
          console.error('Failed to preload video', err);
        }
      };

      preloadVideo();

      return () => {
        if (blobUrl) URL.revokeObjectURL(blobUrl);
      };
    }, [video?.url]);

    const handleVideoLoad = useCallback(() => {
      if (!videoElementRef.current) return;

      const video = videoElementRef.current;
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      setNaturalSize({ width: videoWidth, height: videoHeight });
      const videoAspectRatio = videoWidth / videoHeight;
      setAspectRatio(videoAspectRatio);

      let newWidth = Math.min(480, maxDimensions.width);
      let newHeight = newWidth / videoAspectRatio;

      if (newHeight > maxDimensions.height) {
        newHeight = maxDimensions.height;
        newWidth = newHeight * videoAspectRatio;
      }

      newWidth = Math.max(newWidth, 200);
      newHeight = Math.max(newHeight, 150);

      if (newHeight === 150 && newHeight !== newWidth / videoAspectRatio) {
        newWidth = newHeight * videoAspectRatio;
      }

      setDimensions({
        width: Math.round(newWidth),
        height: Math.round(newHeight)
      });

      setStartDimensions({
        width: Math.round(newWidth),
        height: Math.round(newHeight)
      });

      const isNearMaxWidth = Math.abs(newWidth - maxDimensions.width) < 5;
      const isNearMaxHeight = Math.abs(newHeight - maxDimensions.height) < 5;
      setHasReachedMaxSize(isNearMaxWidth || isNearMaxHeight);

      setIsVideoLoaded(true);
    }, [maxDimensions]);

    const onResizeStart = useCallback(
      (e: React.SyntheticEvent, data: ResizeStartCallbackData) => {
        setStartDimensions({ ...dimensions });
        setIsResizing(true);
      },
      [dimensions]
    );

    const onResize = useCallback(
      (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
        if (!isResizing) return;

        const videoAspectRatio = naturalSize.width > 0 ? naturalSize.width / naturalSize.height : aspectRatio;
        const scaleByWidth = size.width / startDimensions.width;

        if (hasReachedMaxSize && scaleByWidth > 1) return;

        if (
          (dimensions.width >= maxDimensions.width - 5 || dimensions.height >= maxDimensions.height - 5) &&
          scaleByWidth > dimensions.width / startDimensions.width
        )
          return;

        let newWidth = Math.round(size.width);
        let newHeight = Math.round(newWidth / videoAspectRatio);

        if (newWidth > maxDimensions.width) {
          newWidth = maxDimensions.width;
          newHeight = Math.round(newWidth / videoAspectRatio);
        }

        if (newHeight > maxDimensions.height) {
          newHeight = maxDimensions.height;
          newWidth = Math.round(newHeight * videoAspectRatio);
        }

        if (newWidth < 200) {
          newWidth = 200;
          newHeight = Math.round(200 / videoAspectRatio);
        }

        if (newHeight < 150) {
          newHeight = 150;
          newWidth = Math.round(newHeight * videoAspectRatio);
        }

        setDimensions({ width: newWidth, height: newHeight });

        const isNearMaxWidth = Math.abs(newWidth - maxDimensions.width) < 5;
        const isNearMaxHeight = Math.abs(newHeight - maxDimensions.height) < 5;
        setHasReachedMaxSize(isNearMaxWidth || isNearMaxHeight);
      },
      [startDimensions, maxDimensions, isResizing, dimensions, aspectRatio, naturalSize, hasReachedMaxSize]
    );

    const onResizeStop = useCallback(() => {
      setIsResizing(false);
    }, []);

    useImperativeHandle(ref, () => ({
      play: () => videoElementRef.current?.play(),
      pause: () => videoElementRef.current?.pause(),
      adjustTimeBy: (delta: number) => {
        if (videoElementRef.current) {
          videoElementRef.current.currentTime += delta;
        }
      },
      isVideoLoaded: () => isVideoLoaded
    }));

    const handleRemoveVideo = async () => {
      incrementDeleteVideo();
    };

    return (
      <div ref={videoContainer} style={{ width: dimensions.width, height: dimensions.height }}>
        {(!video || isEditingMode) && (
          <Box
            onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
              e.preventDefault();
              const items = e.dataTransfer.items;
              const fileSystemEntries: FileSystemEntry[] = [];
              Array.from(items).forEach(file => {
                const entry = file.webkitGetAsEntry();
                if (entry) fileSystemEntries.push(entry);
              });
              if (video?.id!) await handleRemoveVideo();
              uploadVideo(undefined, fileSystemEntries);
            }}
            onDragOver={e => e.preventDefault()}
            borderRadius={1}
            sx={{
              width: '285px',
              height: '124px',
              background: 'rgba(75, 75, 75, 0.4)',
              border: '1px dashed rgba(171, 171, 171, 1)'
            }}
          >
            <Box component="label">
              <Stack height={1} width={1} justifyContent="center" alignItems="center" gap={2.5}>
                <VideoIcon />
                <Typography fontSize={14} fontWeight={400}>
                  Drop or <span style={{ color: 'rgba(0, 142, 243, 1)' }}>upload</span> video
                </Typography>
              </Stack>
              <UploadArea
                acceptAll={true}
                onUpload={async files => {
                  if (video?.id!) await handleRemoveVideo();
                  uploadVideo(files);
                }}
                multiple={false}
              />
            </Box>
          </Box>
        )}

        {video && !isEditingMode && (
          <div style={{ position: 'relative', width: dimensions.width, height: dimensions.height }}>
            {!isVideoLoaded && (
              <Skeleton
                variant="rectangular"
                width={dimensions.width}
                height={dimensions.height}
                animation="wave"
                sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
              />
            )}
            <ResizableBox
              width={dimensions.width}
              height={dimensions.height}
              minConstraints={[200, 150]}
              maxConstraints={[maxDimensions.width, maxDimensions.height]}
              resizeHandles={['sw']}
              onResizeStart={onResizeStart}
              onResize={onResize}
              onResizeStop={onResizeStop}
              lockAspectRatio={true}
              handle={
                <div
                  className="react-resizable-handle react-resizable-handle-sw"
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    cursor: 'sw-resize',
                    zIndex: 10
                  }}
                />
              }
              style={{
                position: 'relative',
                margin: 0,
                padding: 0,
                overflow: 'hidden'
              }}
            >
              <video
                ref={videoElementRef}
                preload="auto"
                muted
                onLoadedMetadata={handleVideoLoad}
                style={{
                  display: 'block',
                  width: dimensions.width,
                  height: dimensions.height,
                  objectFit: 'fill'
                }}
              />
            </ResizableBox>

            <Stack direction="row" position="absolute" sx={{ top: 5, right: 5, zIndex: 999 }}>
              {/* <IconButton
              sx={{
                width: '28px',
                padding: '4px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
              }}
              size="small"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setIsEditingMode(true);
              }}
            >
              <Edit />
            </IconButton> */}
              <IconButton
                sx={{
                  padding: '4px',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
                }}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleRemoveVideo();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </div>
        )}
      </div>
    );
  }
);

export default VideoPlayer;
