import { useAuth0 } from '@auth0/auth0-react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import JSZip from 'jszip';
import mime from 'mime';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NotificationType } from '../@types/notifications';
import notificationService from '../services/notificationService';
import trackEvent from '../services/trackService';
import { usePlaylistStore } from '../store/players.store';
import { useSongSelector } from '../store/selectors/song.selector';
import { useBeforeUnload } from '../utils/useBeforeUnload';
import { CancelDownloadingDialog } from './CancelDownloadDialog';
import { DialogCloseButton } from './DialogCloseButton';
import { Zip, ZipPassThrough, strToU8 } from 'fflate';
import { saveAs } from 'file-saver';
// @ts-ignore

type DownloadSongFilesDialogProps = {
  songName: string;
  versionId: string;
  onClose: () => void;
  open: boolean;
};

export default function DownloadSongFilesDialog({ versionId, songName, onClose, open }: DownloadSongFilesDialogProps) {
  useBeforeUnload();
  let isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), []);
  const { user } = useAuth0();
  const playlistState = usePlaylistStore(state => state.playListStates.find(playlistState => playlistState.versionId === versionId));

  let playlist: any = null;
  let isSongRendered: boolean;
  let isStemsRendered: boolean;
  let stemsCountForLoading: number;
  let loadedStemsCount: number;
  let waveSurfer: any;
  if (playlistState) {
    playlist = playlistState.playlist;
    isSongRendered = playlistState.isSongRendered;
    isStemsRendered = playlistState.isStemsRendered;
    stemsCountForLoading = playlistState.stemsCountForLoading;
    loadedStemsCount = playlistState.loadedStemsCount;
    waveSurfer = playlistState.waveSurfer;
  }
  const { stems, downloadFiles, isDownloadingFiles, song, songParentId, isOriginalStems, getSongWithStems, isCompressedExists } =
    useSongSelector();
  const [downloadStems, setDownloadStems] = useState(!!stems.length);
  const [downloadSong, setDownloadSong] = useState(!!song);
  const [downloadWidth, setDownloadWidth] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (triggerDownload) {
      if ((!song || isSongRendered) && isStemsRendered) {
        handleDownload();
      } else {
        setDownloadWidth(() => {
          if (stemsCountForLoading === 0) {
            return 33;
          } else {
            return loadedStemsCount === 0 ? 3 : loadedStemsCount * (33 / stemsCountForLoading);
          }
        });
      }
    }
  }, [playlistState, triggerDownload]);

  const handleDownload = async () => {
    setIsDownloading(true);
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    try {
      const isOnlyOneFile = playlist?.tracks?.length === 1 && downloadSong && !downloadStems;

      if (playlist?.tracks?.length === 1) {
        setDownloadWidth(5);
      }

      if (isOnlyOneFile) {
        await downloadSingleFile();
        return;
      }

      let progress = playlist?.tracks?.length === 1 ? 5 : 0;
      const totalFiles = (downloadStems ? playlist?.tracks?.length || 0 : 0) + (downloadSong ? 1 : 0);
      const percentagePerFile = 80 / Math.max(totalFiles, 1);

      const zip = new JSZip();

      let originalStemUrls: string[] = [];
      let originalStemMimes: string[] = [];

      if (downloadStems && playlist?.tracks?.length) {
        const originalStems = (await getSongWithStems(undefined, undefined, true))?.stems || [];
        originalStemUrls = originalStems.map(stem => stem.url);
        originalStemMimes = originalStems.map(stem => stem.mime!);
      }

      // Обрабатываем файлы небольшими группами
      const batchSize = 2; // Размер группы файлов для одновременной обработки

      if (downloadStems && playlist?.tracks?.length) {
        for (let i = 0; i < playlist.tracks.length; i += batchSize) {
          if (signal.aborted) throw new Error('Download cancelled');

          // Обрабатываем группу файлов
          const batch = playlist.tracks.slice(i, Math.min(i + batchSize, playlist.tracks.length));
          const batchPromises = batch.map(async (track: any, batchIndex: any) => {
            const trackIndex = i + batchIndex;

            try {
              const response = await fetch(originalStemUrls[trackIndex], { signal });
              const blob = await response.blob();

              const fileName = track.name.replace(/\.[^/.]+$/, '') + getMimeExtension(originalStemMimes[trackIndex]);

              zip.file(fileName, blob);

              return fileName;
            } catch (error) {
              if (signal.aborted) throw new Error('Download cancelled');
              throw error;
            }
          });

          await Promise.all(batchPromises);

          progress += percentagePerFile * Math.min(batchSize, playlist.tracks.length - i);
          setDownloadWidth(progress);

          await new Promise(resolve => setTimeout(resolve, 10));
        }
      }

      if (downloadSong && waveSurfer) {
        const songUrl = waveSurfer.getMediaElement().src!;
        const response = await fetch(songUrl, { signal });
        const blob = await response.blob();

        const fileName = song?.name.replace(/\.[^/.]+$/, '') + getMimeExtension(song?.mime!);
        zip.file(fileName, blob);

        progress += percentagePerFile;
        setDownloadWidth(progress);
      }

      const zipBlob = await zip.generateAsync(
        {
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: { level: 1 },
          streamFiles: false,
          platform: 'UNIX'
        },
        metadata => {
          setDownloadWidth(80 + metadata.percent * 0.2);
        }
      );

      if (signal.aborted) throw new Error('Download cancelled');

      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(zipBlob);
      link.href = objectUrl;
      link.download = `${songName}.zip`;
      link.click();

      setTimeout(() => URL.revokeObjectURL(objectUrl), 100);

      user && notificationService.createNotification(NotificationType.DownloadSong, songParentId!);
      trackEvent('download', { type: 'song', downloadSong, downloadStems });
    } catch (error: any) {
      if (error.name === 'AbortError' || error.message === 'Download cancelled') {
        console.log('Download cancelled');
      } else {
        console.error('Download failed', error);
      }
    } finally {
      setIsDownloading(false);
      onClose();
    }
  };

  const downloadSingleFile = async () => {
    try {
      if (!downloadSong || !waveSurfer) return;

      const songUrl = waveSurfer.getMediaElement().src!;
      const response = await fetch(songUrl, { signal: abortControllerRef?.current?.signal });

      if (!response.ok) throw new Error('Failed to fetch file');

      const blob = await response.blob();
      const fileName = song?.name.replace(/\.[^/.]+$/, '') + getMimeExtension(song?.mime!);

      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = fileName;
      link.click();

      setTimeout(() => URL.revokeObjectURL(objectUrl), 100);

      user && notificationService.createNotification(NotificationType.DownloadSong, songParentId!);
      trackEvent('download', { type: 'song', downloadSong, downloadStems });
    } catch (error) {
      console.error('Download failed', error);
    }
  };

  const getMimeExtension = (mimeType: string): string => {
    if (!mimeType) return '.mp3';

    if (mimeType === 'audio/flac') return '.flac';

    let ext = mime.getExtension(mimeType);

    if (ext === 'mpga') return '.mp3';
    if (ext === 'oga') return '.ogg';

    return ext ? (ext.startsWith('.') ? ext : `.${ext}`) : '.mp3';
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsDownloading(false);
    setDownloadWidth(0);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      onClose={() => {
        if (triggerDownload) {
          setShowCancelModal(true);
        } else {
          onClose();
        }
      }}
      PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
    >
      <DialogCloseButton
        onClose={() => {
          if (triggerDownload) {
            setShowCancelModal(true);
          } else {
            onClose();
          }
        }}
      />
      <DialogTitle
        sx={{
          width: '90%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500'
        }}
      >
        {triggerDownload ? 'Downloading' : 'Download'} song "{songName}"
      </DialogTitle>
      <DialogContent
        sx={{
          position: 'relative'
        }}
        dividers
      >
        <Box
          sx={{
            width: `${downloadWidth}%`,
            height: '100%',
            background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.85) 88.47%)',
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 10
          }}
        ></Box>
        <Stack flexDirection="column">
          {!!song && (
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={downloadSong}
                onChange={() => setDownloadSong(prev => !prev)}
                color="info"
                checkedIcon={<CheckBoxIcon sx={{ color: '#008EF3 !important' }} />}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: '500'
                }}
              >
                Song
              </Typography>
            </Stack>
          )}
          {!!stems.length && (
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={downloadStems}
                onChange={() => setDownloadStems(prev => !prev)}
                color="info"
                checkedIcon={<CheckBoxIcon sx={{ color: '#008EF3 !important' }} />}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: '500'
                }}
              >
                Stems
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => setTriggerDownload(true)}
          disabled={isDownloading || (!downloadSong && !downloadStems)}
          size="small"
          color="info"
          variant="contained"
        >
          Download files
        </Button>
      </DialogActions>
      {showCancelModal && (
        <CancelDownloadingDialog open={showCancelModal} onCancel={handleCancel} onClose={() => setShowCancelModal(false)} />
      )}
    </Dialog>
  );
}
